import { createApp } from "vue";
import App from "./App.vue";
import "./assets/styles/global.scss";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyArA2ChsJQofjNjVmBPHQ1ZkM_tCyFN8BA",
  authDomain: "public-personal-profile.firebaseapp.com",
  projectId: "public-personal-profile",
  storageBucket: "public-personal-profile.appspot.com",
  messagingSenderId: "197762005600",
  appId: "1:197762005600:web:6169b9e0c0ba320ca3a105",
  measurementId: "G-TCPM8V7VRX",
};

//initialize firebase
const app = initializeApp(firebaseConfig);

// alias
const analytics = getAnalytics(app);

createApp(App).mount("#app");
app.provide("$analytics", analytics);
