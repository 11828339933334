<template>
  <div v-if="this.images.length > 0" class="carousel-container">
    <button
      v-if="this.images.length > 1"
      @click="decrement"
      class="previous-button"
    >
      &lt;
    </button>
    <img :src="currentImg" />
    <button
      v-if="this.images.length > 1"
      @click="increment"
      class="next-button"
    >
      &gt;
    </button>
    <div v-if="this.images.length > 1" class="index-select">
      <button
        v-for="(value, index) in images"
        :key="index"
        @click="setIndex(index)"
        :class="index == imgIndex ? 'active' : 'inactive'"
        :title="value"
      >
        _
      </button>
    </div>
  </div>
</template>

<script>
const errorImg = require("@/assets/images/Projects/card-image.svg");
export default {
  name: "ImgCarousel",
  props: {
    images: [],
  },
  computed: {
    currentImg() {
      if (this.images.length > 0) {
        return this.images[this.imgIndex];
      }
      return errorImg;
    },
  },
  mounted() {
    if (this.images.length > 1) setInterval(this.increment, 15000);
  },
  data() {
    return {
      imgIndex: 0,
    };
  },
  methods: {
    increment() {
      if (this.images.length)
        this.imgIndex = (this.imgIndex + 1) % this.images.length;
    },
    decrement() {
      let value = this.imgIndex - 1;
      if (value < 0) value = this.images.length - 1;

      this.imgIndex = value;
    },
    setIndex(index) {
      this.imgIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-container {
  background: rgb(46, 44, 42);
  display: flex;
  position: relative;

  img {
    fill: white;
    max-height: inherit;
    max-width: inherit;
  }

  button {
    background: rgba(136, 130, 123, 0.8);
    border: none;
    color: #da5103;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background: rgba(41, 39, 37, 0.8);
    }
  }
  .previous-button {
    position: absolute;
    left: 0;
    top: 50%;
  }
  .next-button {
    position: absolute;
    right: 0;
    top: 50%;
  }

  .index-select {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;

    button {
      margin: 5px;
    }
    .active {
      background: rgba(41, 39, 37, 0.8);
      color: #ad4000;
    }
  }
}
</style>
