<template>
  <div class="aoe-container">
    <span class="label" @click="expand = !expand">{{ label }}</span>
    <div class="lists" v-if="expand">
      <figure v-for="(entry, key) of subEntries" :key="key">
        <figcaption>{{ key }}</figcaption>
        <ul>
          <li v-for="detail of entry" :key="detail">{{ detail }}</li>
        </ul>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpertiseItem",
  props: {
    label: {
      required: true,
      default: "No label",
      type: String,
    },
    subEntries: {},
  },
  data() {
    return {
      expand: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.aoe-container {
  font-family: "Inter", sans-serif;
  margin: 0;
  border: 1px solid rgb(185, 175, 160);
  border-radius: 5px;
  overflow: hidden;
  margin: 2px;
  .label {
    display: block;
    font-weight: bold;
    color: white;
    background: #a13b00;
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid rgb(185, 175, 160);
  }

  .lists {
    display: grid;
    grid-template-columns: auto auto auto auto;
    figure {
      margin: 10px;

      figcaption {
        font-weight: bold;
      }
      ul {
        margin: 5px;
      }
    }
  }
}
</style>
