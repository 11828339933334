<template>
  <div class="card">
    <h1>Contact</h1>

    <form type="submit">
      <fieldset>
        <label type="text"> Name</label>
        <input type="text" v-model="name" id="name" />
      </fieldset>
      <fieldset>
        <label type="text"> Email</label>
        <input type="text" v-model="email" id="email" />
      </fieldset>
      <fieldset class="message-fieldset">
        <label type="text"> Message</label>
        <textarea v-model="content" id="content" />
      </fieldset>
      <input type="submit" />
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  props: {},
  data() {
    return {
      name: "",
      email: "",
      content: "",
    };
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Inter");
.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #3333335d;
  color: white;
  margin: 20px;

  h1 {
    font-family: "Orbitron", sans-serif;
    color: #da5103;
  }
}

form {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  fieldset {
    display: flex;
    flex-direction: column;
    border: 0;
    width: 100%;
    margin: 10px;
    padding: 0;

    label {
      font-weight: 600;
      text-align: left;
    }

    input {
      font-family: "Inter", sans-serif;
      background: transparent;
      border: 1px solid #da5103;
      color: white;
      padding: 5px;
    }
  }
  .message-fieldset {
    height: 100%;
    box-sizing: border-box;
    textarea {
      padding: 5px;
      font-family: "Inter", sans-serif;
      resize: none;
      background: transparent;
      border: 1px solid #da5103;
      color: white;
      height: 100%;
    }
  }

  input[type="submit"],
  input[type="button"] {
    border: none;
    background: #da5103;
    width: fit-content;
    color: white;
    font-family: "Orbitron", sans-serif;
    padding: 10px;
    cursor: pointer;

    &:hover {
      color: rgb(204, 204, 204);
      background: #b34200;
    }
  }
}
</style>
