<template>
  <transition name="fade">
    <IntroScene v-if="intro" @close="closeIntro()" />
    <HomePage v-else />
  </transition>
</template>

<script>
import HomePage from "./components/HomePage.vue";
import IntroScene from "./components/IntroScene.vue";

export default {
  name: "App",
  components: {
    // HomePage,
    IntroScene,
    HomePage,
  },
  data() {
    return {
      intro: true,
    };
  },
  methods: {
    closeIntro() {
      this.intro = false;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: black;
  overflow-x: hidden;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
