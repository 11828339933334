<template>
  <div class="project">
    <div class="screenshots">
      <!-- <img
          :src="
            require('@/assets/images/Projects/Kaleidoscope/Kaleidoscope_Screenshot2.png')
          " 
        /> -->
      <ImgCarousel
        class="screenshot"
        :images="[
          require('@/assets/images/Projects/Kaleidoscope/Kaleidoscope_Screenshot.png'),
          require('@/assets/images/Projects/Kaleidoscope/Kaleidoscope_Screenshot2.png'),
        ]"
      />
    </div>
    <div class="description">
      <h2>Kaleidoscope</h2>
      <div class="text-content">
        <a href="https://outcomesecurity.com/platform/"
          >https://outcomesecurity.com/platform/</a
        >
        <p>
          I co-founded a company to try and resolve some of the challenges I
          experienced as a cybersecurity analyst/engineer by building tailored
          software. Our flagship product is Kaleidoscope, a cloud SaaS
          collaborative analysis platform designed for all roles and
          responsibilities in Enterprise security orgs.
        </p>
        <p>
          I was the lead UI/UX developer alongside one additional frontend
          developer as well as two additional backend devs. Kaleidoscope's
          frontend was built on Azure, written in VueJS3/Typescript, with GRPC
          comms and a MySQL and GO backend. Libraries included Chart.JS, TipTap,
          Leaflet and a vast array of custom components providing
          spreadsheet-like functionality, RTF templating, and file rendering in
          multiple styles.
        </p>
      </div>
      <div class="logos-block">
        <img :src="logos['Vue']" class="logo" />
        <img :src="logos['TS']" class="logo" />
        <img :src="logos['JS']" class="logo" />
      </div>
    </div>
  </div>
</template>

<script>
import ImgCarousel from "./ImgCarousel.vue";

export default {
  name: "PortfolioProject",
  props: {},
  components: { ImgCarousel },
  data() {
    return {
      logos: {
        Vue: require("@/assets/images/Projects/Logos/Vue.js_Logo.svg"),
        JS: require("@/assets/images/Projects/Logos/JavaScript_Logo.png"),
        TS: require("@/assets/images/Projects/Logos/Typescript_logo_2020.svg"),
      },
    };
  },
  created() {
    setInterval(this.changeTitle, 50);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.project {
  width: 100%;
  display: grid;
  @media (orientation: landscape) {
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
    height: 70vh;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.1) 80%,
      transparent 0%
    );
  }
  @media (orientation: portrait) {
    grid-template-rows: auto auto;
    grid-template-columns: 100%;
    height: 80vh;
    background: rgba(255, 255, 255, 0.1);
  }
  padding: 40px;
  text-shadow: 1px 1px 1px #575757, 1px 1px 1px #333333;

  h2 {
    font-family: "Orbitron", sans-serif;
    font-weight: bolder;
    font-size: 30px;
    color: #da5103;
    text-align: left;
  }

  .description {
    display: flex;
    flex-direction: column;
    height: 100%;
    h2 {
      font-family: "Orbitron", sans-serif;
      font-weight: bolder;
      font-size: 30px;
      color: #da5103;
      text-align: right;
    }

    .text-content {
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-left: auto;
      margin-right: 0;
      right: 0;
      overflow-y: scroll;
      padding-right: 5px;

      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #da5103;
        border-radius: 3px 0px 0px 3px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ad4000;
      }

      a {
        color: white;
        &:hover {
          color: #da5103;
          fill: #da5103;
          text-decoration: underline;
        }

        &:visited {
          color: cyan;
          fill: cyan;
          text-decoration: underline;
        }
      }
    }

    .logos-block {
      margin-left: auto;
      margin-right: 0;
      .logo {
        margin: 5px;
        @media (orientation: landscape) {
          width: auto;
          height: 5vh;
        }
        @media (orientation: portrait) {
          width: 5vw;
          height: auto;
        }
      }
    }
  }
  .screenshots {
    height: fit-content;
    .screenshot {
      @media (orientation: landscape) {
        max-width: 55vw;
        max-height: 70vh;
      }
      @media (orientation: portrait) {
        max-width: 100%;
      }
    }
  }
}
</style>
