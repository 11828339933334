<template>
  <div class="container" id="container">
    <div class="skyline" @mousemove="parallax">
      <div class="sunset" id="skyline" />
      <img
        class="layer bg-skyline"
        src="@/assets/images/skyline/background_buildings0.png"
        data-speed="0"
        id="bg-skyline"
      />
      <div class="background" data-speed="0" id="bg"></div>
      <img
        class="layer bg-buildings"
        src="@/assets/images/skyline/background_buildings2.png"
        data-speed=".1"
        id="bg-buildings"
      />
      <div class="layer description-box" data-speed=".01">
        <div class="console">
          <span>
            {{ title }}
            <div class="cursor"></div
          ></span>
        </div>
      </div>
      <div class="layer name-plate" data-speed=".01">
        <img src="@/assets/images/skyline/name.png" />
        <button class="enter-button" @click="transitionOut()">[ Enter ]</button>
      </div>
      <img
        class="layer agg-buildings-left"
        src="@/assets/images/skyline/aggregate_buildings2.png"
        data-speed=".2"
        id="agg-buildings-left"
      />
      <img
        class="layer agg-buildings-right"
        src="@/assets/images/skyline/aggregate_buildings1.png"
        data-speed=".2"
        id="agg-buildings-right"
      />
      <img
        class="layer foreground-buildings-left"
        src="@/assets/images/skyline/foreground_building_2.png"
        data-speed="1"
        id="foreground-buildings-left"
      />
      <img
        class="layer foreground-buildings-right"
        src="@/assets/images/skyline/foreground_building_1.png"
        data-speed="1"
        id="foreground-buildings-right"
      />
    </div>
  </div>
</template>

<script>
const titles =
  " \
  Loading OS... \n \
  Downloading components... \n \
  Building analytics... \n \
  Configuring settings... \n \
  \n \
  $~ show roles \n \
  REVERSE ENGINEER \n \
  RED TEAMER \n \
  CTI ANALYST \n \
  FULL STACK DEVELOPER\n \
  UX DESIGNER\n \
  PROJECT MANAGER\n \
  ??? \n \
  \n \
  $~ \
  ";

export default {
  name: "IntroScene",
  props: {},
  data() {
    return {
      title: "",
      titleIndex: 0,
      takeover: false,
      titleIntervalID: null,
    };
  },
  emits: ["close"],
  created() {
    this.titleIntervalID = setInterval(this.changeTitle, 50);
  },
  mounted() {
    this.parallax({ pageX: 0, pageY: 0 });
  },
  methods: {
    changeTitle() {
      if (this.title.length < titles.length) {
        this.title += titles.charAt(this.titleIndex);
        this.titleIndex++;
      } else {
        clearInterval(this.titleIntervalID);
      }
    },
    parallax(e) {
      if (!this.takeover) {
        document.querySelectorAll(".layer").forEach((layer) => {
          const speed = layer.getAttribute("data-speed") || 2;
          const x = (window.innerWidth - e.pageX * speed) / 10;
          const y = (window.innerHeight - e.pageY * speed) / 10;

          layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
        });
      }
    },
    transitionOut() {
      const foregroundLeft = document.getElementById(
        "foreground-buildings-left"
      );
      foregroundLeft.classList.add("darken");

      const foregroundRight = document.getElementById(
        "foreground-buildings-right"
      );
      foregroundRight.classList.add("darken");

      const aggLeft = document.getElementById("agg-buildings-left");
      aggLeft.classList.add("darken");

      const aggRight = document.getElementById("agg-buildings-right");
      aggRight.classList.add("darken");

      const bgBuildings = document.getElementById("bg-buildings");
      bgBuildings.classList.add("darken");

      const bg = document.getElementById("bg-skyline");
      bg.classList.add("darken");

      document.getElementById("skyline").classList.add("animate-sunset");
      setInterval(() => {
        this.$emit("close");
      }, 5000);
      // setInterval(this.navigate, 5000)
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Orbitron");

.container {
  height: 100vh;
  width: 100vw;
  color: white;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .skyline {
    height: 100%;
    width: 100%;

    .layer {
      position: absolute;
    }

    .sunset {
      position: absolute;
      width: 100%;
      height: 500vh;
      bottom: 0;
      background: linear-gradient(
        to top,
        #fdfdfd 0%,
        #aa3c00 30%,
        #5a0053 60%,
        #0e0500 100%
      );
    }

    .animate-sunset {
      animation: sunset 5s ease-in forwards;
      @keyframes sunset {
        100% {
          transform: translateY(400vh);
        }
      }
    }

    .flyout {
      animation: flyout var(--speed) ease-in forwards;
      @keyframes flyout {
        100% {
          transform: var(--movement) scale(5);
        }
      }
    }

    .background {
      position: fixed;
      background: transparent
        url("http://assets.iceable.com/img/noise-transparent.png") repeat 0 0;
      background-repeat: repeat;
      visibility: visible;
      animation: bg-animation 0.2s infinite;
      opacity: 0.9;
      top: -50%;
      left: -50%;
      right: -50%;
      bottom: -50%;
      width: 200%;
      height: 200vh;
      @keyframes bg-animation {
        0% {
          transform: translate(0, 0);
        }
        10% {
          transform: translate(-5%, -5%);
        }
        20% {
          transform: translate(-10%, 5%);
        }
        30% {
          transform: translate(5%, -10%);
        }
        40% {
          transform: translate(-5%, 15%);
        }
        50% {
          transform: translate(-10%, 5%);
        }
        60% {
          transform: translate(15%, 0);
        }
        70% {
          transform: translate(0, 10%);
        }
        80% {
          transform: translate(-15%, 0);
        }
        90% {
          transform: translate(10%, 5%);
        }
        100% {
          transform: translate(5%, 0);
        }
      }
    }

    .bg-skyline {
      bottom: 0;
      right: 5vw;
      width: 120vw;
      height: auto;
    }

    .darken {
      animation: darken 5s ease-in forwards;
      @keyframes darken {
        0% {
          filter: brightness(100%);
        }
        100% {
          filter: brightness(0%);
        }
      }
    }

    .bg-buildings {
      bottom: 0;
      left: -10vw;
    }
    .name-plate {
      bottom: 20vh;
      left: 15vw;
      width: 41vw;
      display: flex;
      justify-items: space-between;
      justify-content: space-between;
      align-items: flex-end;

      img {
        display: block;
        width: 30vw;
        height: auto;
      }

      .enter-button {
        display: block;
        color: white;
        padding: 3px 20px 3px 20px;
        border: none;
        background: rgba(124, 124, 124, 0.2);
        font-family: "Orbitron", sans-serif;
        font-weight: bolder;
        font-size: 30px;
        white-space: nowrap;
        cursor: pointer;
        z-index: 100;

        &:hover {
          color: cyan;
          background-color: rgba(56, 56, 56, 0.8);
        }
      }
    }
    .description-box {
      position: absolute;
      top: 10vh;
      right: 40vw;
      width: 30vw;
      height: 30vh;
      padding: 10px;

      .console {
        position: relative;
        width: 100%;
        height: 100%;

        span {
          position: absolute;
          color: black;
          filter: drop-shadow(1px 1px 1px cyan);
          font-family: "Orbitron", sans-serif;
          font-weight: bolder;
          white-space: pre-line;
          bottom: 0;
          left: 0;
          margin: 10px;
          text-align: left;
          .cursor {
            display: inline-block;
            width: 10px;
            height: 15px;
            background-color: black;
            animation: flash 1s infinite;

            @keyframes flash {
              50% {
                background-color: black;
              }
              51% {
                background-color: transparent;
              }
              100% {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .agg-buildings-left {
      bottom: 5vh;
      right: 75vw;
    }
    .agg-buildings-right {
      bottom: 0%;
      left: 60vw;
    }
    .foreground-buildings-left {
      bottom: 0;
      right: 85vw;
    }
    .foreground-buildings-right {
      bottom: 0;
      left: 70vw;
    }
    .layer-7 {
      bottom: 0;
      left: 2vw;
    }
  }
}
</style>
