<template>
  <div class="background">
    <div class="rain" id="rain">
      <div class="front-row" id="front-row">
        <div
          v-for="(entry, index) of rainData"
          :key="index"
          class="drop"
          :style="{
            left: entry.drop.x,
            bottom: entry.drop.y,
            'animation-delay': entry.drop.animDelay,
            'animation-duration': entry.drop.animDuration,
          }"
        >
          <div
            class="stem"
            :style="{
              'animation-delay': entry.stem.animDelay,
              'animation-duration': entry.stem.animDuration,
            }"
          ></div>
        </div>
      </div>
      <div class="back-row" id="front-row">
        <div
          v-for="(entry, index) of rainData"
          :key="index"
          class="drop"
          :style="{
            right: entry.drop.x,
            bottom: entry.drop.y,
            'animation-delay': entry.drop.animDelay,
            'animation-duration': entry.drop.animDuration,
          }"
        >
          <div
            class="stem"
            :style="{
              'animation-delay': entry.stem.animDelay,
              'animation-duration': entry.stem.animDuration,
            }"
          ></div>
        </div>
      </div>

      <div class="darken-row" />
    </div>

    <div class="container">
      <div class="scroll-section">
        <div class="about">
          <div class="content">
            <h2>Pat Talcott</h2>
            <div class="text-content">
              With over 10 years spent working across a myriad of roles, I'm an
              experienced cybersecurity practioner with a passion for mission
              and a wide variety of skills. I've led teams as large as 15
              dedicated ICs, built and executed network exploitation and
              protection strategies, tracked state-backed threat actors from
              numerous countries, and designed and developed tooling from
              conception to production. I think people are the most important
              asset to cybersecurity and am dedicated to supporting those folks
              through whatever means necessary whether it be training, strategic
              leadership, or arming them with the tools required to protect
              their networks.
              <br />
              <h3>Areas of Expertise</h3>
              <div class="aoe">
                <ExpertiseListItem
                  label="Reverse Engineering"
                  :subEntries="{
                    Languages: ['x86', 'x64', 'Android'],
                    Tools: [
                      'IDA Pro',
                      'x64dbg',
                      'radare2',
                      'Reflector',
                      'ILSpy',
                      'bytecode-viewer',
                    ],
                  }"
                />
                <ExpertiseListItem
                  label="Red Teaming"
                  :subEntries="{
                    Platforms: ['Windows', 'Nix', 'Firewalls/switches'],
                  }"
                />
                <ExpertiseListItem
                  label="Software Development"
                  :subEntries="{
                    Languages: ['C', 'C++', 'Java', 'Python', 'Ruby On Rails'],
                  }"
                />
                <ExpertiseListItem
                  label="Web Development"
                  :subEntries="{
                    Languages: [
                      'VueJS 2/3',
                      'Javascript',
                      'Typescript',
                      'HTML',
                      'CSS',
                      'PHP',
                      'C#.NET',
                    ],
                  }"
                />
                <ExpertiseListItem
                  label="DataBase Administration"
                  :subEntries="{ SQL: ['MySQL', 'MSSQL'] }"
                />
                <ExpertiseListItem
                  label="Project Management"
                  :subEntries="{
                    Exp: [
                      'High Priority Project Management',
                      'Lead teams from 5-15 people',
                      'Set Mission goals and priorities and maintained project timelines',
                    ],
                  }"
                />
              </div>
            </div>
          </div>
          <div class="profile-image">
            <img :src="require('@/assets/images/profile_img_normal.png')" />
          </div>
        </div>
      </div>
      <div class="scroll-section">
        <PortfolioProject />
      </div>
      <div class="scroll-section">
        <div class="contact">
          <SocialsCard />
          <ContactForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import SocialsCard from "./SocialsCard.vue";
import PortfolioProject from "./Project.vue";
import ExpertiseListItem from "./ExpertiseListItem.vue";

export default {
  name: "HomePage",
  props: {},
  components: {
    ContactForm,
    SocialsCard,
    PortfolioProject,
    ExpertiseListItem,
  },
  data() {
    return {
      rainData: [],
    };
  },
  created() {
    //populate vars for rain
    let increment = 0;
    while (increment < 100) {
      //couple random numbers to use for various randomizations
      //random number between 98 and 1
      var randoHundo = Math.floor(Math.random() * (98 - 1 + 1) + 1);
      //random number between 5 and 2
      var randoFiver = Math.floor(Math.random() * (5 - 2 + 1) + 2);
      //increment
      increment += randoFiver;

      this.rainData.push({
        drop: {
          x: increment + "%",
          y: randoFiver + randoFiver - 1 + 100 + "%",
          animDelay: "0." + randoHundo + "s",
          animDuration: "0.5" + randoHundo + "s",
        },
        stem: {
          animDelay: "0." + randoHundo + "s",
          animDuration: "0.5" + randoHundo + "s",
        },
      });
    }
  },
  mounted() {
    this.rain();
  },
  methods: {
    rain() {},
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Orbitron");
@import url("https://fonts.googleapis.com/css?family=Inter");
.background {
  background-color: black;
  color: white;
  background-image: url("@/assets/images/cyberpunk_street.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  .rain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .back-row {
      z-index: 1;
      bottom: 60px;
      opacity: 0.6;
    }

    .drop {
      position: absolute;
      bottom: 100%;
      width: 15px;
      height: 120px;
      pointer-events: none;
      animation: drop 0.5s linear infinite;
    }

    @keyframes drop {
      0% {
        transform: translateY(0vh);
      }
      75% {
        transform: translateY(100vh);
      }
      100% {
        transform: translateY(100vh);
      }
    }

    .stem {
      width: 1px;
      height: 100%;
      margin-left: 7px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.6)
      );
      animation: stem 0.5s linear infinite;
    }

    @keyframes stem {
      0% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      75% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    .darken-row {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
    }
  }

  .container {
    overflow: scroll;
    height: 100vh;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    .scroll-section {
      scroll-snap-align: start;
      display: flex;
      align-items: center;
      height: 100vh;
      scroll-snap-type: y proximity;

      // @media (orientation: portrait) {
      //   .about {
      //     flex-direction: column;
      //   }
      // }
      .about {
        width: 100%;
        display: grid;
        @media (orientation: landscape) {
          grid-template-columns: 60% 40%;
          grid-template-rows: 100%;
          height: 70vh;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.1) 80%,
            transparent 0%
          );
        }
        @media (orientation: portrait) {
          grid-template-rows: 60% 40%;
          grid-template-columns: 100%;
          height: 80vh;
          background: rgba(255, 255, 255, 0.1);
        }
        align-items: center;
        padding: 40px;
        text-shadow: 1px 1px 1px #575757, 1px 1px 1px #333333;

        .content {
          font-family: "Inter", sans-serif;
          display: flex;
          flex-direction: column;
          height: 100%;
          h2 {
            font-family: "Orbitron", sans-serif;
            font-weight: bolder;
            font-size: 30px;
            color: #da5103;
            text-align: left;
          }

          .text-content {
            text-align: left;
            margin-left: 0;
            margin-right: auto;
            left: 0;
            overflow-y: scroll;
            padding-right: 5px;
            height: 100%;
            padding-bottom: 5px;

            &::-webkit-scrollbar {
              width: 5px;
              background: transparent;
            }
            &::-webkit-scrollbar-track {
              background: transparent;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #da5103;
              border-radius: 3px 0px 0px 3px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #ad4000;
            }

            .aoe {
              padding: 5px;
            }
          }
        }

        @media (orientation: portrait) {
          .profile-image {
            img {
              max-width: 55vw;
              max-height: 70vh;
            }
          }
        }

        @media (orientation: landscape) {
          .profile-image {
            img {
              max-width: 55vw;
              max-height: 70vh;
            }
          }
        }
      }

      .contact {
        display: grid;
        width: 100%;
        @media (orientation: landscape) {
          grid-template-columns: 40% 60%;
          grid-template-rows: 100%;
          height: 70vh;
        }
        @media (orientation: portrait) {
          grid-template-rows: 40% 60%;
          grid-template-columns: 100%;
          height: 80vh;
        }
      }
    }
  }
}
</style>
